import * as React from 'react';
import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import theme from '../../../util/theme';
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryGroup,
} from 'victory';
import { StaticCompaniesMapSegment } from './StaticCompaniesMap';
import { UnicornsToolTip } from './UnicornsToolTip';
import { AppText } from '../../ui';
import { laptopSize, mobileSize, desktopSize } from '../../../util/variables';
import { mapCompanyImages } from './insightsData';
import { FourPlacesMapComponent } from '../research3/FourPlacesMap';
import { rgba } from 'polished';
import moment from 'moment';

interface IGraphsComponentProps {
  data: any[];
  dataBaseline: any[];
}

const GrowthChart: React.FunctionComponent<IGraphsComponentProps> = React.memo(
  ({ data, dataBaseline }) => {
    return (
      <VictoryChart domainPadding={5} height={489} width={755}>
        <VictoryLine
          // labels={({ datum }) => `$${Math.round(datum.amount).toLocaleString()}M`}
          data={data}
          style={{
            data: {
              stroke: theme.PRIMARY_COLOR,
              strokeWidth: 3,
            },
          }}
          x="year"
          y="amount"
        />
        <VictoryLine
          // labels={({ datum }) => `$${Math.round(datum.amount).toLocaleString()}M`}
          data={dataBaseline}
          style={{
            data: {
              stroke: theme.GREY_600,
              strokeWidth: 3,
              strokeDasharray: '10,10',
            },
          }}
          x="year"
          y="amount"
        />
        {/* <VictoryAxis
          dependentAxis
          style={{
            tickLabels: {
              fontFamily: theme.PRIMARY_FONT,
              // letterSpacing: '1.67px',
              fontSize: 12,
              color: 'rgb(26, 26, 26, 0.6)',
              // opacity: '0.6',
            },
            axis: { stroke: 'transparent' },
          }}
          tickFormat={t => `$${t.toLocaleString()}M`}
        /> */}
        <VictoryAxis
          tickFormat={(year, index) =>
            index % 10 === 0 ? `${moment(year, 'YYYY-MM-DD').format('MM/YY')}` : ''
          }
          style={{
            tickLabels: {
              fontFamily: theme.PRIMARY_FONT,
              letterSpacing: '1.3px',
              fontSize: 12,
              color: 'rgb(26, 26, 26)',
              // opacity: '0.6',
            },
            axis: { stroke: theme.BLACK_COLOR, opacity: '0.2' },
          }}
        />
      </VictoryChart>
    );
  },
);

const AmountOfFundingChartDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: -30px;
  width: 500px;
  @media ${laptopSize} {
    margin-top: -40px;
    max-width: 500px;
    margin: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
    width: 100%;
    justify-content: center;
  }
`;

const ArrowDataBox = styled.div`
  flex: 1;
  min-width: 100px;
`;

interface LastItem {
  lastItem?: boolean;
}

const TooltipText = styled(AppText)`
  display: inline-block;
  text-align: center;
  max-width: 316px;
  padding: 20px;
`;

const ArrowBoxDataContainer = styled.div<LastItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 82px;
  margin-left: -10px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: -10px;
  position: relative;
  border-radius: ${props => (props.lastItem ? '4px' : '0px')};
  background-image: linear-gradient(
    to left,
    rgba(69, 174, 235, 0.2),
    rgba(69, 174, 235, 0.2) 36%,
    rgba(255, 255, 255, 0) 100%
  );
  :after,
  :before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -40px;
    position: absolute;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -41px;
    position: absolute;
  }
`;

const ArrowBoxLabel = styled(AppText)`
  max-width: 70px;
  opacity: 0.6;
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.67px;
  text-align: center;
  vertical-align: text-bottom;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxNumber = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 30px;
  flex-wrap: nowrap;
  @media ${laptopSize} {
    justify-content: center;
  }
  @media ${mobileSize} {
    flex-wrap: wrap;
  }
`;

const MapDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  position: relative;
  max-width: 90vw;
  max-height: 350px;
  min-height: 280px;
  margin: 20px;
  z-index: 1;
  padding: 0;
  @media ${desktopSize} {
    max-width: 50vw;
  }
  @media ${laptopSize} {
    min-height: auto;
    margin: 0;
    max-width: 90vw;
  }
  @media ${mobileSize} {
    margin: 0;
    max-width: 90vw;
    margin-bottom: 20px;
  }
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

export { GrowthChart };
