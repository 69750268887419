import * as React from 'react';

import { Helmet } from 'react-helmet';
import IndexLayout from '../layouts';
import favicon from '../content/img/icon/favicon.ico';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { laptopSize, desktopSize, mobileSize, smallMobileSize } from '../util/variables';
import { Wrapper, AppText, NavBar } from '../components/ui/';
import theme from '../util/theme';
import { useEffect, useMemo, useState } from 'react';
import { prismicClient, Prismic, RichText } from '../services';
import ReactTooltip from 'react-tooltip';
import { YearlyFinancingGraph } from '../components/PageSegments/research/YearlyFinancingGraph';
import { GrowthChart } from '../components/PageSegments/research/GrowthChart';
import moment from 'moment';
import ellipseGrey from '../content/img/research/ellipse_grey.png';
import ellipseBlue from '../content/img/research/ellipse_blue.png';
import sortArrow from '../content/img/research/arrow-down-sort-blue.png';
import filterIcon from '../content/img/research/filter-icon.png';
import closeIcon from '../content/img/research/close-icon.png';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { BottomNavSegment, Footer } from '../components/PageSegments/';
import { FilterTableModal } from '../components/Modal/FilterTableModal';
import Select, { StylesConfig } from 'react-select';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useImageList } from '../content/img/research/imagesList';
import { S3_COMPANY_IMAGE_BUCKET_BASE } from '../../src/util/variables';
import { Img } from 'react-image';

const signalColors = {
  light_green: '#CDE6DB',
  dark_green: '#036A3C',
  light_yellow: '#F0DBC3',
  dark_yellow: '#E29433',
  light_red: '#EACFCE',
  dark_red: '#C23934',
};

const customControlStyles: React.CSSProperties = {
  maxHeight: '200px',
  overflow: 'auto',
  borderRadius: '8px',
  fontFamily: theme.PRIMARY_FONT,
  fontSize: '12px',
  // letterSpacing: '0.2px',
  backgroundColor: theme.WHITE_COLOR,
  lineHeight: 1.4,
};

const selectStyle: StylesConfig<MyOptionType, boolean> = {
  control: (provided, state) => {
    // provided has CSSObject type
    // state has ControlProps type

    // return type is CSSObject which means this line will throw error if uncommented
    // return false;

    return {
      ...provided,
      ...customControlStyles,
    };
  },
  option: (base, state) => ({
    ...base,
    fontFamily: theme.PRIMARY_FONT,
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: 1.4,
  }),
  menu: styles => ({ ...styles, zIndex: 9 }),
};

const returnOnlyDomain = (text: string) => {
  if (!!text) {
    return text.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
  }
  return '';
};

const IndexTemplate = ({ data, pageContext }: any) => {
  const trend = pageContext.slug;

  const researchData = useStaticQuery(
    graphql`
      query researchData {
        allBase11SummaryCsv {
          nodes {
            id
            metric_name
            metric_display_name
            metric_value
          }
        }
        allDiveCompaniesCsv {
          nodes {
            id
            name
            description
            founded_year
            stage
            stage_group
            total_raised
            last_raise
            rank_org
            valuation
            linkedin
            founder_one_name
            founder_one_role
            founder_one_linkedin
            founder_one_exits
            founder_one_cb_rank
            founder_two_name
            founder_two_role
            founder_two_linkedin
            founder_two_exits
            founder_two_cb_rank
            founder_three_name
            founder_three_role
            founder_three_linkedin
            founder_three_exits
            founder_three_cb_rank
            company_url
            hq_city
            hq_country
            domain
            employees
            employees_smoothed
            avg_monthly_growth_rate_monthly
            avg_monthly_growth_rate_quarterly
            avg_monthly_growth_rate_annual
            growth_rate_inflection_factor
            rmse_annual
            dive_slug
            dive_name
            dive_segment
            is_port_co
            investors
            growth_score
          }
        }
        allDiveSummaryCsv {
          nodes {
            id
            dive_slug
            dive_name
            total_companies
            total_raised
            total_exits
            portfolio_companies
            maturity_benchmark
            dive_hiring_and_funding_growth_score
            base11_opportunity_score
            base11_opportunity_score_display
          }
        }
        allFundingChartCsv {
          nodes {
            id
            dive_slug
            dive_name
            year
            total_raised
            cumulative_total_raised
          }
        }
        allGrowthChartCsv {
          nodes {
            id
            dive_slug
            month_of
            cumulative_composite_growth
            total_companies
            cumulative_composite_growth_per_co
            benchmark_month
            months_since_benchmark
            benchmark_composite_growth
          }
        }
        allInvestorsCsv {
          nodes {
            id
            dive_slug
            dive_name
            investor_name
            investments
            rounds_invested_in
            rounds_led
            avg_company_quality
            investment_domains
            investment_names
          }
        }
        allRoundsCsv {
          nodes {
            id
            cb_uuid
            round_uuid
            round_stage
            round_date
            round_amount_raised
            round_is_equity
            round_cb_rank
            round_valuation
            round_lead
            domain
            round_year
            dive_slug
            dive_name
            dive_segment
            is_port_co
            year
            name
            investors
          }
        }
        allSegmentBubbleChartCsv {
          nodes {
            id
            dive_slug
            dive_name
            dive_segment
            segment_funding
            dive_funding
            pct_of_dive_funding
            pct_of_dive_funding_formatted
          }
        }
        allTopCompaniesCsv {
          nodes {
            id
            cb_uuid
            name
            domain
            dive_slug
            dive_name
            rank_org
            is_port_co
            employees_smoothed
            avg_monthly_growth_rate_monthly
            avg_monthly_growth_rate_quarterly
            avg_monthly_growth_rate_annual
            growth_rate_inflection_factor
            rmse_annual
            company_rank
          }
        }
        allTopInvestorsCsv {
          nodes {
            id
            dive_slug
            dive_name
            investor_name
            investments
            rounds_invested_in
            rounds_led
            avg_company_quality
            investment_domains
            investment_names
          }
        }
        allSegmentDescriptionsCsv {
          nodes {
            id
            dive_slug
            dive_name
            dive_segment
            dive_segment_description
          }
        }
      }
    `,
  );

  const [diveData, setDiveData] = useState<any>(null);

  const [tableSortField, setTableSortField] = useState('');
  const [tableSortAscending, setTableSortAscending] = useState(false);

  const [roundsSortValue, setRoundsSortValue] = useState(false);

  const [showMore, setShowMore] = useState([]);

  const [filters, setFilters] = useState({
    location: [],
    stage: [],
    investor: [],
  });
  // const [filtersModal, setFiltersModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'research-dive'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        setDiveData(
          response.results,
          // .sort((a, b) => {
          //   const aDate = new Date(a.data.date);
          //   const bDate = new Date(b.data.date);
          //   return bDate.getTime() - aDate.getTime();
          // }),
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [trend]);

  const generateTrendPrismicData = () => {
    if (!!trend && !!diveData && !!diveData.length) {
      const data = diveData.slice().filter((item: any) => item.data.slug_name === trend);
      return !!data.length ? data[0].data : {};
    }
    return {};
  };

  const generateTrendData = () => {
    if (!!trend) {
      const data = researchData.allDiveSummaryCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data[0] : {};
    }
    return {};
  };

  const generateBubbleChartData = () => {
    if (!!trend) {
      const data = researchData.allSegmentBubbleChartCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data : [];
    }
    return [];
  };

  const yearlyFinancingChartData = () => {
    if (!!trend) {
      const sixYearsAgo = Number(moment().year()) - 6;
      const data = researchData.allFundingChartCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend)
        .filter((item: any) => item.year > sixYearsAgo);
      if (!!data.length) {
        return data.map((item: any) => ({
          year: item.year,
          amount: Number(item.total_raised) / 1_000_000,
        }));
      }
      return [];
    }
    return [];
  };

  const topInvestorsChartData = () => {
    if (!!trend) {
      const data = researchData.allInvestorsCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data : [];
    }
    return [];
  };

  const topRoundsTableData = () => {
    if (!!trend) {
      const data = researchData.allRoundsCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data : [];
    }
    return [];
  };

  const segmentsInDiveData = () => {
    if (!!trend) {
      const data = researchData.allDiveCompaniesCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend)
        .map((item: any) => item.dive_segment)
        .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);
      return !!data.length ? data : [];
    }
    return [];
  };

  const returnFilterOptions = () => {
    if (!!trend) {
      const optionsLocation = researchData.allDiveCompaniesCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend)
        .map((item: any) => item.hq_country)
        .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
        .filter((item: string) => Boolean(item));

      const optionsStages = researchData.allDiveCompaniesCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend)
        .map((item: any) => item.stage)
        .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
        .filter((item: string) => Boolean(item));

      const optionInvestors = [
        ...researchData.allDiveCompaniesCsv.nodes
          .slice()
          .filter((item: any) => item.dive_slug === trend)
          .map((item: any) => item.investors.split(', '))
          .reduce((a: any, b: any) => a.concat(b)),
      ]
        .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
        .filter((item: string) => Boolean(item))
        .filter((item: string) => !item.match(/and \d+ others/g));

      return optionsLocation.length && optionsStages.length
        ? {
            location: optionsLocation,
            stage: optionsStages,
            investor: optionInvestors,
          }
        : {
            location: [],
            stage: [],
            investor: [],
          };
    }
    return {
      location: [],
      stage: [],
      investor: [],
    };
  };

  const dataCompaniesInSegmentData = (segment: string) => {
    if (!!trend) {
      const data = researchData.allDiveCompaniesCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend && item.dive_segment === segment);
      return !!data.length ? data : [];
    }
    return [];
  };

  const topCompaniesDataTrend = () => {
    if (!!trend) {
      const data = researchData.allTopCompaniesCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data : [];
    }
    return [];
  };

  const topInvestorsDataTrend = () => {
    if (!!trend) {
      const data = researchData.allTopInvestorsCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      return !!data.length ? data : [];
    }
    return [];
  };

  const findSegmentDescription = (segment: string) => {
    if (!!trend) {
      const data = researchData.allSegmentDescriptionsCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend)
        .filter((item: any) => item.dive_segment === segment);
      return !!data.length ? data[0].dive_segment_description : '';
    }
    return [];
  };

  const growthChartData = () => {
    if (!!trend) {
      const data = researchData.allGrowthChartCsv.nodes
        .slice()
        .filter((item: any) => item.dive_slug === trend);
      if (!!data.length) {
        return {
          data: data.map((item: any) => ({
            year: item.month_of,
            amount: Number(item.cumulative_composite_growth_per_co),
          })),
          baseline: data.map((item: any) => ({
            year: item.month_of,
            amount: Number(item.benchmark_composite_growth),
          })),
        };
      }
      return {
        data: [],
        baseline: [],
      };
    }
    return {
      data: [],
      baseline: [],
    };
  };

  const openLinkInNewTab = (link: string) => {
    if (typeof window !== 'undefined') {
      window.open(link, '_blank');
    }
  };

  const filtersUsedNumber = useMemo(() => {
    return (
      Number(filters.location.length) +
      Number(filters.stage.length) +
      Number(filters.investor.length)
    );
  }, [filters]);

  const BreakWordAtSpace = (text: string) => {
    return (
      <ScoreText number={text.split(' ')[0] === 'Developing' ? 85 : 50}>
        {text.split(' ')[0]} <br /> {Boolean(text.split(' ').length > 1) && text.split(' ')[1]}
      </ScoreText>
    );
  };

  return (
    <IndexLayout>
      <Helmet
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      >
        <title>Base11 Research - {trend}</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/companies" />
        <meta charSet="UTF-8" />
      </Helmet>
      <Main>
        <NavBar invert anchorToHere />

        <OurTeamContainer>
          <ReactTooltip
            multiline
            // place="top"
            effect="solid"
            css={{
              maxWidth: 300,
              lineHeight: 1.4,
              textAlign: 'center',
              fontFamily: theme.PRIMARY_FONT,
            }}
            backgroundColor="rgba(0, 0, 0, 0.9)"
          />
          <ResearchWrapper>
            {/* {filtersModal && (
              <FilterTableModal
                filters={filters}
                setFilters={setFilters}
                options={returnFilterOptions()}
                showModal={filtersModal}
                toggleModal={() => setFiltersModal(!filtersModal)}
              />
            )} */}
            <ResearchTitle>{generateTrendData()?.dive_name}</ResearchTitle>
            <FlexDivRow>
              {/* {generateTrendData().dive_hiring_and_funding_growth_score >= 0.8
                  ? 'High'
                  : generateTrendData().dive_hiring_and_funding_growth_score < 0.2
                  ? 'Low'
                  : 'Medium'} */}
              {BreakWordAtSpace(generateTrendData().base11_opportunity_score_display)}
              <ResearchSubTitleTop>
                Real Economy <br /> Opportunity{' '}
                <SpanContainer
                  style={{ marginTop: '4px' }}
                  data-tip="Measures a trend’s potential for venture investment relative to the other Real Economy trends identified by Base10. Trends that are early in their development and experiencing accelerating growth will score higher."
                >
                  <AiOutlineInfoCircle color={theme.GREY_700} fontSize="14px" />
                </SpanContainer>
              </ResearchSubTitleTop>
            </FlexDivRow>
            <SectionTitleDiv>
              <SectionTitle>Summary</SectionTitle>
              <SectionAfter />
            </SectionTitleDiv>
            <SplitDiv>
              <div>
                <LongDescriptionText>
                  {RichText.render(generateTrendPrismicData().dive_description_long)}
                </LongDescriptionText>
                {Boolean(generateTrendPrismicData()?.dive_article_link?.url) && (
                  <OutsideAnchor
                    href={generateTrendPrismicData().dive_article_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link to article
                  </OutsideAnchor>
                )}
              </div>
              <BorderDiv>
                <ThreeItemsDiv>
                  <FlexDivCol>
                    <TrendDataNumberText>{generateTrendData().total_companies}</TrendDataNumberText>
                    <TrendDataItemText>Featured Companies</TrendDataItemText>
                  </FlexDivCol>
                  <FlexDivCol>
                    <TrendDataNumberText>
                      ${Math.round(generateTrendData().total_raised / 1000000).toLocaleString()}M
                    </TrendDataNumberText>
                    <TrendDataItemText>Total Raised In Trend</TrendDataItemText>
                  </FlexDivCol>
                  <FlexDivCol>
                    <TrendDataNumberText>
                      {Number(generateTrendData().portfolio_companies)}
                    </TrendDataNumberText>
                    <TrendDataItemText>B10 Portfolio Companies</TrendDataItemText>
                  </FlexDivCol>
                </ThreeItemsDiv>
                <PaddingLeftDiv>
                  <HiddenBorderDiv>
                    {Boolean(generateTrendData()?.dive_hiring_and_funding_growth_score) && (
                      <>
                        <ChartTitle>
                          Hiring and Funding Speed{' '}
                          <SpanContainer
                            style={{ marginTop: '4px' }}
                            data-tip="Measures a trend’s rate of growth relative to other Real Economy trends identified by Base10. The faster startups in the trend hire new employees or raise new capital, the higher this metric will be."
                          >
                            <AiOutlineInfoCircle color={theme.NEW_BLACK_COLOR} fontSize="14px" />
                          </SpanContainer>
                        </ChartTitle>
                        <ColorBar
                          percentage={
                            generateTrendData().dive_hiring_and_funding_growth_score * 100
                          }
                        />
                        <FlexBetweenDiv>
                          <ChartLabelText>Slowest</ChartLabelText>
                          <ChartLabelText>Fastest</ChartLabelText>
                        </FlexBetweenDiv>
                      </>
                    )}
                    {Boolean(generateTrendData()?.maturity_benchmark) && (
                      <>
                        <ChartTitle>
                          Trend Maturity{' '}
                          <SpanContainer
                            style={{ marginTop: '4px' }}
                            data-tip="Measures a trend’s development stage relative to other Real Economy trends identified by Base10. This metric incorporates funding stage, number of exits, capital per company, and company size to determine the trend’s overall maturity."
                          >
                            <AiOutlineInfoCircle color={theme.NEW_BLACK_COLOR} fontSize="14px" />
                          </SpanContainer>
                        </ChartTitle>
                        <ColorBar percentage={generateTrendData().maturity_benchmark * 100} />
                        <FlexBetweenDiv>
                          <ChartLabelText>Early</ChartLabelText>
                          <ChartLabelText>Growth</ChartLabelText>
                        </FlexBetweenDiv>
                      </>
                    )}
                  </HiddenBorderDiv>
                  <HiddenBorderDiv>
                    <FlexDivRowTop style={{ marginTop: '32px' }}>
                      <LastSegmentLabel>Top Investors</LastSegmentLabel>
                      <FlexDivRow style={{ flexWrap: 'wrap' }}>
                        {topInvestorsDataTrend().map((item: any) =>
                          useImageList.includes(item.investor_name) ? (
                            <div>
                              <a
                                href={`https://${item.domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <CompanyImg2
                                  src={require(`../content/img/research/logos/${item.investor_name}.jpg`)}
                                  alt={item.investor_name}
                                />
                              </a>
                            </div>
                          ) : (
                            <a
                              href={`https://${item.domain}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '28px',
                                  margin: '2px',
                                  marginRight: '12px',
                                  marginBottom: '12px',
                                  objectFit: 'contain',
                                }}
                                src={[
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()}.png`,
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()}.jpg`,
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()}.jpeg`,
                                ]}
                                unloader={
                                  <AppText
                                    style={{ color: theme.BLACK_COLOR, marginRight: '12px' }}
                                  >
                                    {item.investor_name}
                                  </AppText>
                                }
                                loader={
                                  <AppText
                                    style={{ color: theme.BLACK_COLOR, marginRight: '12px' }}
                                  >
                                    {item.investor_name}
                                  </AppText>
                                }
                              />
                            </a>
                          ),
                        )}
                      </FlexDivRow>
                    </FlexDivRowTop>
                    <FlexDivRowTop style={{ marginTop: '24px' }}>
                      <LastSegmentLabel>Top Companies</LastSegmentLabel>
                      <FlexDivRow style={{ flexWrap: 'wrap' }}>
                        {topCompaniesDataTrend().map((item: any) =>
                          useImageList.includes(item.name) ? (
                            <div>
                              <a
                                href={`https://${item.domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <CompanyImg2
                                  src={require(`../content/img/research/logos/${item.name}.jpg`)}
                                  alt={item.name}
                                />
                              </a>
                            </div>
                          ) : (
                            <a
                              href={`https://${item.domain}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img
                                key={`top-company-${item.id}`}
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '28px',
                                  margin: '2px',
                                  marginRight: '12px',
                                  marginBottom: '12px',
                                  objectFit: 'contain',
                                }}
                                src={[
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                    item.domain,
                                  )}.png`,
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                    item.domain,
                                  )}.jpg`,
                                  `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                    item.domain,
                                  )}.jpeg`,
                                ]}
                                unloader={
                                  <AppText
                                    style={{ color: theme.BLACK_COLOR, marginRight: '12px' }}
                                  >
                                    {item.name}
                                  </AppText>
                                }
                                loader={
                                  <AppText
                                    style={{ color: theme.BLACK_COLOR, marginRight: '12px' }}
                                  >
                                    {item.name}
                                  </AppText>
                                }
                              />
                            </a>
                          ),
                        )}
                      </FlexDivRow>
                    </FlexDivRowTop>
                  </HiddenBorderDiv>
                </PaddingLeftDiv>
              </BorderDiv>
            </SplitDiv>
            <SectionTitleDiv>
              <SectionTitle>Market</SectionTitle>
              <SectionAfter />
            </SectionTitleDiv>
            <MarketGrid>
              <MarketBorderDiv>
                <MarketGraphTitle>Growth</MarketGraphTitle>

                <MarketGraphSubtitle>
                  Trend growth is a proprietary composite score based on the speed of hiring and
                  fundraising. Base11 benchmarks trend growth against the growth of other trends
                  analysed through the Base11 platform.
                </MarketGraphSubtitle>
                <div
                  style={{
                    height: 'calc(100% - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <FlexCenterDiv>
                    <img src={ellipseGrey} height="14px" />
                    <GrowthGraphLabel style={{ color: theme.GREY_600, marginRight: '12px' }}>
                      Avg Trend Benchmark
                    </GrowthGraphLabel>
                    <img src={ellipseBlue} height="14px" />
                    <GrowthGraphLabel style={{ color: theme.PRIMARY_COLOR }}>
                      {generateTrendData()?.dive_name}
                    </GrowthGraphLabel>
                  </FlexCenterDiv>
                  <div>
                    <GrowthChart
                      data={growthChartData().data}
                      dataBaseline={growthChartData().baseline}
                    />
                  </div>
                  <MonthOfLabel>Month Of</MonthOfLabel>
                </div>
              </MarketBorderDiv>
              <GridColumnDiv>
                <MarketBorderDiv>
                  <MarketGraphTitle>Yearly Financing</MarketGraphTitle>
                  <MarketGraphSubtitle>
                    The total amount raised by companies in the trend by year.
                  </MarketGraphSubtitle>
                  <YearlyFinancingGraph data={yearlyFinancingChartData()} />
                </MarketBorderDiv>
                <MarketBorderDiv>
                  <MarketGraphTitle>Funding by Segment</MarketGraphTitle>
                  <BubblesWrap>
                    {generateBubbleChartData().map((item: any) => (
                      <FundingBySegmentSegment
                        key={item.id}
                        data-tip={
                          item.pct_of_dive_funding > 0.05
                            ? undefined
                            : `${item.dive_segment} (${item.pct_of_dive_funding_formatted})`
                        }
                        percentage={item.pct_of_dive_funding}
                      >
                        {item.pct_of_dive_funding > 0.05 && (
                          <>
                            <span style={{ fontSize: '12px', marginRight: '8px' }}>
                              {item.dive_segment}
                            </span>
                            <span style={{ fontSize: '16px' }}>
                              {item.pct_of_dive_funding_formatted}
                            </span>
                          </>
                        )}
                      </FundingBySegmentSegment>
                    ))}
                  </BubblesWrap>
                </MarketBorderDiv>
              </GridColumnDiv>
            </MarketGrid>
            <SectionTitleDiv>
              <SectionTitle>Segments & Companies</SectionTitle>
              <SectionAfter />
            </SectionTitleDiv>
            <FiltersGrid>
              <SelectContainer>
                <LabelText>Locations</LabelText>
                <Select
                  isMulti
                  styles={selectStyle}
                  value={filters.location.map(item => ({ label: item, value: item }))}
                  options={returnFilterOptions()
                    .location.sort()
                    .map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                  onChange={(e: any) => {
                    !!e.length
                      ? setFilters({
                          ...filters,
                          location: e.map((item: any) => item.value),
                        })
                      : setFilters({ ...filters, location: [] });
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <LabelText>Stages</LabelText>
                <Select
                  isMulti
                  styles={selectStyle}
                  value={filters.stage.map(item => ({ label: item, value: item }))}
                  options={returnFilterOptions()
                    .stage.sort()
                    .map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                  onChange={(e: any) => {
                    !!e.length
                      ? setFilters({
                          ...filters,
                          stage: e.map((item: any) => item.value),
                        })
                      : setFilters({ ...filters, stage: [] });
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <LabelText>Investors</LabelText>
                <Select
                  isMulti
                  styles={selectStyle}
                  value={filters.investor.map(item => ({ label: item, value: item }))}
                  options={returnFilterOptions()
                    .investor.sort()
                    .map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                  onChange={(e: any) => {
                    !!e.length
                      ? setFilters({
                          ...filters,
                          investor: e.map((item: any) => item.value),
                        })
                      : setFilters({ ...filters, investor: [] });
                  }}
                />
              </SelectContainer>
              <SelectContainerSort>
                <LabelText>Sort by</LabelText>
                <SortDiv>
                  {/* <FilterButton onClick={() => setFiltersModal(!filtersModal)}>
                Filters &nbsp; <img src={filterIcon} />
                {filtersUsedNumber ? <FiltersNumber>{filtersUsedNumber}</FiltersNumber> : ''}
              </FilterButton> */}
                  <FitlerSelect
                    name="tableSortField"
                    onChange={e => {
                      trackCustomEvent({
                        action: 'Click',
                        category: 'Sort',
                        label: `Trend detail sort changed to ${e.target.value}`,
                      });
                      setTableSortField(e.target.value);
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="total_raised">Amount Raised</option>
                    <option value="founded_year">Founded</option>
                    <option value="employees">Employees</option>
                  </FitlerSelect>
                  {!!tableSortField && (
                    <SortOrderDiv onClick={() => setTableSortAscending(!tableSortAscending)}>
                      <img
                        src={sortArrow}
                        style={{
                          height: '24px',
                          transform: tableSortAscending ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                        alt="Change sort order"
                      />
                    </SortOrderDiv>
                  )}
                </SortDiv>
              </SelectContainerSort>
            </FiltersGrid>

            {/* <FiltersWrap>
              {filters.location.map((item: string) => (
                <SelectedFilterDiv key={`location-filter-${item}`}>
                  <FilterLabelText> {item}</FilterLabelText>{' '}
                  <CloseImg
                    src={closeIcon}
                    alt="Remove filter"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        location: [...filters.location.filter((el: any) => el !== item)],
                      });
                    }}
                  />
                </SelectedFilterDiv>
              ))}
              {filters.stage.map((item: string) => (
                <SelectedFilterDiv key={`stage-filter-${item}`}>
                  <FilterLabelText>{item}</FilterLabelText>{' '}
                  <CloseImg
                    src={closeIcon}
                    alt="Remove filter"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        stage: [...filters.stage.filter((el: any) => el !== item)],
                      });
                    }}
                  />
                </SelectedFilterDiv>
              ))}
              {filters.investor.map((item: string) => (
                <SelectedFilterDiv key={`investor-filter-${item}`}>
                  <FilterLabelText>{item}</FilterLabelText>{' '}
                  <CloseImg
                    src={closeIcon}
                    alt="Remove filter"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        investor: [...filters.investor.filter((el: any) => el !== item)],
                      });
                    }}
                  />
                </SelectedFilterDiv>
              ))}
            </FiltersWrap> */}
            {segmentsInDiveData().map((segment: any) => (
              <MarketBorderDivTable key={segment} style={{ marginTop: '33px' }}>
                <TableInfoDiv>
                  <MarketGraphTitle>{segment}</MarketGraphTitle>
                  <MarketGraphSubTitle>{findSegmentDescription(segment)}</MarketGraphSubTitle>
                </TableInfoDiv>
                <TableScroll>
                  <TrendTable>
                    <TrendTHead>
                      <TrendTR>
                        <TrendTH>
                          <TableHeaderText>Company</TableHeaderText>
                        </TrendTH>
                        <TrendTH>
                          <TableHeaderText>Founded</TableHeaderText>
                        </TrendTH>
                        <TrendTH>
                          <TableHeaderText>Stage</TableHeaderText>
                        </TrendTH>
                        <TrendTH>
                          <TableHeaderText>Raised</TableHeaderText>
                        </TrendTH>
                        <TrendTH>
                          <TableHeaderText>Employees (est)</TableHeaderText>
                        </TrendTH>
                        <TrendTH>
                          <TableHeaderText>HQ Location</TableHeaderText>
                        </TrendTH>
                        <TrendTH style={{ width: '180px' }}>
                          <TableHeaderText>Founder</TableHeaderText>
                        </TrendTH>
                        <TrendTH style={{ width: '300px' }}>
                          <TableHeaderText>Investors</TableHeaderText>
                        </TrendTH>
                      </TrendTR>
                    </TrendTHead>
                    <tbody>
                      {dataCompaniesInSegmentData(segment)
                        .slice()
                        .filter(
                          (item: any) =>
                            (!filters.location.length &&
                              !filters.stage.length &&
                              !filters.investor.length) ||
                            (filters.location.length &&
                              filters.location.includes(item.hq_country)) ||
                            (filters.stage.length && filters.stage.includes(item.stage)) ||
                            (filters.investor.length &&
                              !!item.investors &&
                              item.investors
                                .split(', ')
                                .some((element: string) => filters.investor.includes(element))),
                        )
                        .sort((a: any, b: any) => {
                          if (tableSortField === '') {
                            return 0;
                          }

                          const valA = Number(a[tableSortField]);
                          const valB = Number(b[tableSortField]);

                          if (valA > valB) {
                            return tableSortAscending ? 1 : -1;
                          }

                          if (valA < valB) {
                            return tableSortAscending ? -1 : 1;
                          }

                          return 0;
                        })
                        .map((company: any) => (
                          <TrendTRCompany
                            key={company.id}
                            onClick={() => openLinkInNewTab(company.company_url)}
                          >
                            <TrendTD
                              style={{ height: '40px', maxHeight: '40px' }}
                              className="company-name"
                            >
                              {useImageList.includes(company.name) ? (
                                <FloatStartDiv>
                                  <CompanyImg
                                    src={require(`../content/img/research/logos/${company.name}.jpg`)}
                                    alt={company.name}
                                  />
                                </FloatStartDiv>
                              ) : (
                                <FloatStartDiv style={{ fontWeight: 600, textAlign: 'left' }}>
                                  <Img
                                    style={{
                                      maxWidth: '100px',
                                      maxHeight: '28px',
                                      margin: '2px',
                                    }}
                                    src={[
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                        company.domain,
                                      )}.png`,
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                        company.domain,
                                      )}.jpg`,
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                        company.domain,
                                      )}.jpeg`,
                                    ]}
                                    unloader={
                                      <TableBodyText style={{ fontWeight: 600 }}>
                                        {company.name}
                                      </TableBodyText>
                                    }
                                    loader={
                                      <TableBodyText style={{ fontWeight: 600 }}>
                                        {company.name}
                                      </TableBodyText>
                                    }
                                  />
                                </FloatStartDiv>
                              )}
                            </TrendTD>
                            <TrendTD style={{ width: '70px' }}>
                              <TableBodyText>{Math.round(company.founded_year)}</TableBodyText>
                            </TrendTD>
                            <TrendTD style={{ width: '80px' }}>
                              <TableBodyText style={{ textAlign: 'left' }}>
                                {company.stage}
                              </TableBodyText>
                            </TrendTD>

                            <TrendTD style={{ width: '80px' }}>
                              <TableBodyText style={{ textAlign: 'right' }}>
                                {Math.round(Number(company.total_raised) / 1_000_000)
                                  ? `$${Math.round(Number(company.total_raised) / 1_000_000)}M`
                                  : '-'}
                              </TableBodyText>
                            </TrendTD>

                            <TrendTD style={{ width: '70px' }}>
                              <TableBodyText>
                                {company.employees ? Math.round(company.employees) : '-'}
                              </TableBodyText>
                            </TrendTD>

                            <TrendTD>
                              <TableBodyText
                                style={{ maxWidth: '170px', textAlign: 'left' }}
                                data-tip={`${company.hq_city}${
                                  Boolean(company.hq_city) && Boolean(company.hq_country) && ', '
                                }${company.hq_country}`}
                              >
                                {company.hq_city}
                                {Boolean(company.hq_city) && Boolean(company.hq_country) && ', '}
                                {company.hq_country}
                              </TableBodyText>
                            </TrendTD>

                            <TrendTD
                              style={{ cursor: 'auto' }}
                              onClick={(e: any) => e.stopPropagation()}
                            >
                              {/* <WrappedText showMore={showMore} id="Companies Founders"> */}
                              <div
                                style={{ display: 'flex', maxHeight: '40px', alignItems: 'center' }}
                              >
                                <TableBodyText
                                  style={{
                                    maxWidth: showMore.includes('Companies Founders')
                                      ? 'min-content'
                                      : '130px',
                                    lineHeight: 1.2,
                                    display: 'inline',
                                    textAlign: 'left',
                                  }}
                                  expand={showMore.includes('Companies Founders')}
                                >
                                  {company.founder_one_linkedin ? (
                                    <FounderA
                                      href={company.founder_one_linkedin}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {company.founder_one_name}
                                    </FounderA>
                                  ) : (
                                    company.founder_one_name
                                  )}
                                  {company.founder_two_linkedin ? (
                                    <>
                                      {', '}
                                      <FounderA
                                        href={company.founder_two_linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {company.founder_two_name}
                                      </FounderA>
                                    </>
                                  ) : (
                                    company.founder_two_name && `, ${company.founder_two_name}`
                                  )}
                                  {company.founder_three_linkedin ? (
                                    <>
                                      {', '}
                                      <FounderA
                                        href={company.founder_three_linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {company.founder_three_name}
                                      </FounderA>
                                    </>
                                  ) : (
                                    company.founder_three_name && `, ${company.founder_three_name}`
                                  )}
                                </TableBodyText>
                                <MoreButtonStyle
                                  onClick={() => {
                                    showMore.includes('Companies Founders')
                                      ? setShowMore(
                                          showMore.filter(i => i !== 'Companies Founders'),
                                        )
                                      : setShowMore(showMore.concat('Companies Founders'));
                                  }}
                                >
                                  {showMore.includes('Companies Founders') ? 'less' : 'more'}
                                </MoreButtonStyle>
                              </div>
                            </TrendTD>
                            <TrendTD style={{ width: '300px' }}>
                              <TableBodyTextWrap
                                style={{ textAlign: 'left' }}
                                data-tip={company.investors}
                              >
                                {company.investors}
                              </TableBodyTextWrap>
                            </TrendTD>
                          </TrendTRCompany>
                        ))}
                    </tbody>
                  </TrendTable>
                </TableScroll>
              </MarketBorderDivTable>
            ))}
            <SectionTitleDiv>
              <SectionTitle>Financing</SectionTitle>
              <SectionAfter />
            </SectionTitleDiv>
            <TwoTablesDiv>
              <div>
                <MarketBorderDivTable>
                  <MarketGraphTitle>Top Investors</MarketGraphTitle>
                  <TableScroll style={{ maxHeight: '700px' }}>
                    <TrendTable>
                      <TrendTHead>
                        <TrendTR>
                          <TrendTH>
                            <TableHeaderText>Investor</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Companies Backed</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Rounds Participated In</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Rounds Led</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Companies</TableHeaderText>
                          </TrendTH>
                        </TrendTR>
                      </TrendTHead>
                      <tbody>
                        {topInvestorsChartData().map((item: any) => (
                          <TrendTR key={item.id}>
                            <TrendTD>
                              {useImageList.includes(item.name) ? (
                                <TableBodyTextOld>
                                  <CompanyImg
                                    src={require(`../content/img/research/logos/${item.name}.jpg`)}
                                    alt={item.name}
                                  />
                                </TableBodyTextOld>
                              ) : (
                                <TableBodyTextOld>
                                  <Img
                                    style={{ maxWidth: '100px', maxHeight: '28px', margin: '2px' }}
                                    src={[
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()}.png`,
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()}.jpg`,
                                      `${S3_COMPANY_IMAGE_BUCKET_BASE}/${item.investor_name
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()}.jpeg`,
                                    ]}
                                    unloader={item.investor_name}
                                    loader={item.investor_name}
                                  />
                                </TableBodyTextOld>
                              )}
                            </TrendTD>
                            <TrendTD>
                              <TableBodyTextOld>{item.investments}</TableBodyTextOld>
                            </TrendTD>
                            <TrendTD>
                              <TableBodyTextOld>{item.rounds_invested_in}</TableBodyTextOld>
                            </TrendTD>
                            <TrendTD>
                              <TableBodyTextOld>{item.rounds_led}</TableBodyTextOld>
                            </TrendTD>
                            <TrendTD>
                              <TableBodyTextOld
                                style={{
                                  maxWidth: '145px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  textAlign: 'left',
                                  // lineHeight: '30px',
                                }}
                                data-tip={item.investment_names}
                              >
                                {item.investment_names}
                              </TableBodyTextOld>
                            </TrendTD>
                          </TrendTR>
                        ))}
                      </tbody>
                    </TrendTable>
                  </TableScroll>
                </MarketBorderDivTable>
              </div>
              <div>
                <MarketBorderDivTable>
                  <FlexDivRow>
                    <MarketGraphTitle
                      style={{
                        cursor: 'pointer',
                        borderBottom: roundsSortValue ? 'none' : `2px solid ${theme.PRIMARY_COLOR}`,
                        opacity: roundsSortValue ? 0.5 : 1,
                        marginRight: '20px',
                      }}
                      onClick={() => setRoundsSortValue(false)}
                    >
                      Recent Rounds
                    </MarketGraphTitle>
                    <MarketGraphTitle
                      style={{
                        cursor: 'pointer',
                        borderBottom: roundsSortValue ? `2px solid ${theme.PRIMARY_COLOR}` : 'none',
                        opacity: roundsSortValue ? 1 : 0.5,
                      }}
                      onClick={() => setRoundsSortValue(true)}
                    >
                      Largest Rounds
                    </MarketGraphTitle>
                  </FlexDivRow>
                  <TableScroll style={{ maxHeight: '700px' }}>
                    <TrendTable>
                      <TrendTHead>
                        <TrendTR>
                          <TrendTH>
                            <TableHeaderText>Company</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Segment</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Round Date</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Round Size</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Round Stage</TableHeaderText>
                          </TrendTH>
                          <TrendTH>
                            <TableHeaderText>Investors</TableHeaderText>
                          </TrendTH>
                        </TrendTR>
                      </TrendTHead>
                      <tbody>
                        {topRoundsTableData()
                          .slice()
                          .sort((a: any, b: any) => {
                            if (roundsSortValue) {
                              const valA = Number(a.round_amount_raised);
                              const valB = Number(b.round_amount_raised);
                              if (valA > valB) {
                                return -1;
                              } else if (valA < valB) {
                                return 1;
                              } else return 0;
                            } else {
                              const valA = moment(a.round_date, 'YYYY-MM-DD').utc();
                              const valB = moment(b.round_date, 'YYYY-MM-DD').utc();
                              if (valA > valB) {
                                return -1;
                              } else if (valA < valB) {
                                return 1;
                              } else return 0;
                            }
                          })
                          .map(
                            (item: any, index: number) =>
                              index < 100 && (
                                <TrendTR key={item.id}>
                                  <TrendTD>
                                    {useImageList.includes(item.name) ? (
                                      <TableBodyTextOld>
                                        <CompanyImg
                                          style={{ padding: '2px' }}
                                          src={require(`../content/img/research/logos/${item.name}.jpg`)}
                                          alt={item.name}
                                        />
                                      </TableBodyTextOld>
                                    ) : (
                                      <TableBodyTextOld>
                                        <Img
                                          style={{
                                            maxWidth: '100px',
                                            maxHeight: '28px',
                                            margin: '2px',
                                          }}
                                          src={[
                                            `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                              item.domain,
                                            )}.png`,
                                            `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                              item.domain,
                                            )}.jpg`,
                                            `${S3_COMPANY_IMAGE_BUCKET_BASE}/${returnOnlyDomain(
                                              item.domain,
                                            )}.jpeg`,
                                          ]}
                                          unloader={item.name}
                                          loader={item.name}
                                        />
                                      </TableBodyTextOld>
                                    )}
                                  </TrendTD>
                                  <TrendTD>
                                    <TableBodyTextOld>{item.dive_segment}</TableBodyTextOld>
                                  </TrendTD>
                                  <TrendTD>
                                    <TableBodyTextOld>
                                      {moment(item.round_date, 'YYYY-MM-DD').format('MM/YY')}
                                    </TableBodyTextOld>
                                  </TrendTD>
                                  <TrendTD>
                                    <TableBodyTextOld>
                                      $
                                      {Math.round(
                                        Number(item.round_amount_raised) / 1_000_000,
                                      ).toLocaleString()}
                                      M
                                    </TableBodyTextOld>
                                  </TrendTD>
                                  <TrendTD>
                                    <TableBodyTextOld>{item.round_stage}</TableBodyTextOld>
                                  </TrendTD>
                                  <TrendTD>
                                    <TableBodyTextWrapOld
                                      style={{ maxWidth: '100px' }}
                                      data-tip={item.investors}
                                    >
                                      {item.investors}
                                    </TableBodyTextWrapOld>
                                  </TrendTD>
                                </TrendTR>
                              ),
                          )}
                      </tbody>
                    </TrendTable>
                  </TableScroll>
                </MarketBorderDivTable>
              </div>
            </TwoTablesDiv>
          </ResearchWrapper>
        </OurTeamContainer>
        <Footer />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const FloatStartDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  overflow: hidden;
  img {
    padding-left: 5px;
    max-height: 25px;
  }
`;

const MoreButtonStyle = styled.p`
  padding: 0px;
  margin: 0px;
  /* padding-left: 5px; */
  border: none;
  background: transparent;
  color: ${theme.PRIMARY_COLOR};
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
`;

const CompanyImg = styled.img`
  max-width: 100px;
  max-height: 28px;
  margin: 2px;
`;

const CompanyImg2 = styled.img`
  max-width: 100px;
  max-height: 28px;
  margin: 2px;
  margin-right: 12px;
  margin-bottom: 12px;
  object-fit: contain;
`;

const TableInfoDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 8px;
  @media ${mobileSize} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MarketGraphSubTitle = styled(AppText)`
  font-size: 16px;
  line-height: 30px;
  color: #9b9b9b;
  margin-left: 16px;
  margin-bottom: 8px;
  @media ${mobileSize} {
    margin-left: 0pxpx;
    font-size: 14px;
    line-height: 1.5;
  }
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
  }
`;

const LabelText = styled(AppText)`
  color: ${theme.GREY_700};
`;

const FiltersContainer = styled.div`
  min-height: max-content;
  display: flex;
  grid-gap: 25px;
  justify-content: space-between;
  @media ${mobileSize} {
    flex-direction: column;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  margin-right: 16px;
  margin-top: 16px;
  height: max-content;
  transform: 200ms all ease-in-out;
  :hover {
    max-height: auto;
  }
  @media ${mobileSize} {
    width: 100%;
  }
`;
const SelectContainerSort = styled(SelectContainer)`
  padding-left: 16px;
  border-left: 1px solid ${theme.GREY_300};
  @media ${mobileSize} {
    border-left: none;
    padding-left: 0px;
  }
`;

const CloseImg = styled.img`
  cursor: pointer;
  margin-left: 16px;
`;

const TrendDataNumberText = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.2px;
  width: 100%;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 21px;
    width: 100%;
    text-align: center;
    letter-spacing: 0.13px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

const SelectedFilterDiv = styled.div`
  height: 48px;
  border: 1px solid ${theme.GREY_600};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 16px;
  border-radius: 2px;
  transition: 200ms all ease-in-out;
  :hover {
    background-color: ${theme.GREY_300};
  }
`;

const FilterLabelText = styled(AppText as any)`
  font-size: 14px;
  line-height: 21px;
  color: ${theme.GREY_800};
`;

const SortOrderDiv = styled.div`
  height: 40px;
  min-width: 40px;
  border: 1px solid ${theme.GREY_300};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  transition: 200ms all ease-in-out;
  border-radius: 8px;
  :hover {
    /* background-color: ${theme.GREY_300}; */
    border: 1px solid ${theme.GREY_500};
  }
`;

const SortDiv = styled.div`
  display: flex;
  width: 100%;
  /* margin-top: 32px;
  margin-bottom: 32px; */
  align-items: center;
`;

const FitlerSelect = styled.select`
  background: transparent;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  /* border-bottom: 1px solid ${theme.PRIMARY_COLOR}; */
  border: 1px solid ${theme.GREY_300};
  margin: 0px;
  /* margin-right: 8px; */
  width: 100%;
  text-overflow: ellipsis;
  padding-left: 16px;
  option {
    font-family: ${theme.PRIMARY_FONT};
  }
  transition: 200ms all ease-in-out;
  cursor: pointer;
  height: 38px;

  border-radius: 8px;
  :hover {
    /* background-color: ${theme.GREY_300}; */
    border: 1px solid ${theme.GREY_500};
  }
  @media ${mobileSize} {
    margin: 0px;
  }
`;

const FilterButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  text-transform: uppercase;
  padding: 0px 24px;
  height: 56px;
  background: ${theme.WHITE_COLOR};
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid ${theme.GREY_300};
  margin-right: 22px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  border-radius: 2px;
  transition: 200ms all ease-in-out;
  :hover {
    background-color: ${theme.GREY_300};
    border: 1px solid ${theme.GREY_700};
  }
`;

const FiltersNumber = styled(AppText)`
  background-color: ${theme.PRIMARY_COLOR};
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
`;

const GrowthGraphLabel = styled(AppText)`
  font-weight: 500;
  @media ${mobileSize} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const FounderA = styled.a`
  color: ${theme.PRIMARY_COLOR};
  transition: 200ms all ease-in-out;
  :hover {
    opacity: 0.5;
  }
`;

const HiddenBorderDiv = styled.div`
  @media ${mobileSize} {
    border: 1px solid ${theme.GREY_600};
    border-radius: 2px;
    padding: 0px 24px;
    padding-bottom: 12px;
    margin-top: 8px;
  }
`;

const FlexCenterDiv = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  @media ${smallMobileSize} {
    display: grid;
    grid-template-columns: 20px 1fr;
  }
`;

const TableHeaderText = styled(AppText)`
  color: ${theme.GREY_700};
  text-align: center;
  line-height: 1.2;
  padding: 5px;
  font-size: 11px;
  min-height: 100%;
  border-bottom: 2px solid ${theme.GREY_800};
`;

interface ExpandedProp {
  expand?: boolean;
}

const TableBodyText = styled(AppText)<ExpandedProp>`
  color: ${theme.GREY_800};
  text-align: center;
  line-height: 1.2;
  padding: 5px 8px;
  font-size: 13px;
  line-height: 30px;
  max-width: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
`;

const TableBodyTextOld = styled(AppText)`
  color: ${theme.GREY_800};
  text-align: center;
  line-height: 1.2;
  vertical-align: center;
  padding: 5px;
  margin-top: auto;
  font-size: 13px;
`;

const TableBodyTextWrap = styled(TableBodyText)`
  max-width: 300px;
  /* max-height: 40px; */
  /* height: 100%; */
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  /* display: inline-block; */
  text-overflow: ellipsis;
`;
const TableBodyTextWrapOld = styled(TableBodyTextOld)`
  max-width: 300px;
  /* max-height: 40px; */
  /* height: 100%; */
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  /* display: inline-block; */
  text-overflow: ellipsis;
`;

const TwoTablesDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 26px;
  margin-top: 32px;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
  }
`;

const TrendTable = styled.table`
  width: 100%;
  position: relative;
`;
const TrendTHead = styled.thead`
  position: sticky;
  top: 0;
  background: ${theme.WHITE_COLOR};
  z-index: 3;
  @media ${smallMobileSize} {
    position: relative;
  }
`;

const TrendTH = styled.th`
  vertical-align: bottom;
  :first-child {
    position: sticky;
    left: 0px;
    background: ${theme.WHITE_COLOR};
    z-index: 2;
    @media ${smallMobileSize} {
      position: relative;
    }
  }
`;

const TrendTR = styled.tr`
  border-bottom: 1px solid ${theme.GREY_500};
  /* transition: all 400ms ease-in-out; */
  /* :hover {
    background: ${theme.GREY_300};
  } */
  :last-child {
    border-bottom: none;
  }
`;

const TrendTRCompany = styled(TrendTR)`
  cursor: pointer;
  :hover {
    background: ${theme.GREY_300};
    .company-name {
      /* transition: all 400ms ease-in-out; */
      background: ${theme.GREY_300};
    }
  }
`;

const TrendTD = styled.td`
  text-align: center;
  :first-child {
    position: sticky;
    left: 0;
    background: ${theme.WHITE_COLOR};
    z-index: 2;
    @media ${smallMobileSize} {
      position: relative;
      border-bottom: 1px solid ${theme.GREY_500};
    }
  }
`;

const MonthOfLabel = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  text-align: center;
`;

const BubblesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 8px;
`;

const FiltersWrap = styled(BubblesWrap)`
  grid-gap: 16px;
  @media ${mobileSize} {
    display: none;
  }
`;

interface PercentageProp {
  percentage: number;
}

const FundingBySegmentSegment = styled.div<PercentageProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  line-height: 1;
  padding: 2px;
  width: ${props => (props.percentage < 0.05 ? '23%' : props.percentage < 0.1 ? '48%' : '100%')};
  height: ${props => props.percentage * 200}px;
  min-height: 35px;
  color: ${props => (props.percentage < 0.25 ? theme.GREY_800 : theme.WHITE_COLOR)};
  background-color: rgba(
    68,
    174,
    235,
    ${props =>
      props.percentage * 2.5 > 1 ? 1 : props.percentage * 2.5 < 0.2 ? 0.2 : props.percentage * 2.5}
  );
`;

const GridColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
`;

const MarketGraphTitle = styled(AppText)`
  font-size: 20px;
  line-height: 1.5;
  color: ${theme.NEW_BLACK_COLOR};
  letter-spacing: 0.2;
  margin: 0px;
  margin-bottom: 8px;
  font-weight: 600;
  @media ${laptopSize} {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
`;
const MarketGraphSubtitle = styled(AppText)`
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.GREY_700};
  letter-spacing: 0.2;
  margin: 0px;
  margin-bottom: 8px;
  font-weight: 400;
  @media ${laptopSize} {
    font-size: 12px;
  }
`;

const MarketGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 24px;
  margin-top: 32px;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
  }
`;

const TableScroll = styled.div`
  overflow: auto;
  max-height: 380px;
  @media ${mobileSize} {
    max-height: 500px;
  }
`;

const MarketBorderDiv = styled.div`
  border: 1px solid ${theme.GREY_600};
  border-radius: 8px;
  padding: 24px;
`;

const MarketBorderDivTable = styled.div`
  border: 1px solid ${theme.GREY_600};
  border-radius: 8px;
  padding: 24px;
`;

const PaddingLeftDiv = styled.div`
  padding-left: 15px;
  @media ${laptopSize} {
    padding-left: 0px;
  }
`;

const LastSegmentLabel = styled(AppText)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${theme.GREY_800};
  background-color: ${theme.GREY_300};
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: 400;
  max-width: fit-content;
  height: fit-content;
  @media ${mobileSize} {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 12px;
  }
`;

const FlexBetweenDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ChartTitle = styled(AppText)`
  color: ${theme.PITCH_BLACK};
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const ChartLabelText = styled(AppText)`
  color: ${theme.GREY_500};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin: 0px;
  margin-top: 4px;
  @media ${mobileSize} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ColorBar = styled.div<PercentageProp>`
  display: block;
  position: relative;
  width: 100%;
  height: 24px;
  background-color: rgba(68, 174, 235, 0.25);
  border-radius: 8px;
  ::before {
    position: absolute;
    display: block;
    content: '';
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: ${theme.PRIMARY_COLOR};
    width: ${props => `${props.percentage}%`};
    border-radius: 8px;
    /* width: 70%; */
  }
`;

const OutsideAnchor = styled.a`
  display: block;
  color: ${theme.PRIMARY_COLOR};
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  font-weight: 700;
  margin-top: 32px;
  transition: 400ms all ease-in-out;
  @media ${laptopSize} {
    margin-top: 24px;
  }
  :hover {
    color: ${theme.PRIMARY_COLOR};
    opacity: 0.7;
  }
`;

const FlexDivRow = styled.div`
  display: flex;
`;

const FlexDivRowTop = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  @media ${mobileSize} {
    grid-template-columns: 100px 1fr;
  }
  @media ${smallMobileSize} {
    grid-template-columns: 1fr;
  }
`;

const FlexDivCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 150px;
  @media ${mobileSize} {
    max-width: 90%;
    border: 1px solid ${theme.GREY_600};
    min-width: 80px;
    width: 30%;
    padding: 11px;
    border-radius: 2px;
    height: 25vw;
    min-height: 100px;
    justify-content: center;
  }
  @media ${smallMobileSize} {
    min-width: 100%;
    min-height: 80px;
    height: 80px;
  }
`;

const ThreeItemsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  @media ${mobileSize} {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    display: flex;
    grid-gap: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media ${smallMobileSize} {
    flex-direction: column;
  }
`;

const BorderDiv = styled.div`
  border: 1px solid ${theme.GREY_600};
  padding-right: 32px;
  padding-left: 32px;
  /* padding-left: 47px; */
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 8px;
  @media ${laptopSize} {
    border: none;
  }
  @media ${mobileSize} {
    padding: 0px;
  }
`;

const SplitDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const TrendDataItemText = styled(AppText)`
  color: ${theme.GREY_700};
  /* NOTE: This color is too light */
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  @media ${laptopSize} {
    font-size: 10px;
    line-height: 15px;
    width: 100%;
    padding: 0px 10%;
    text-align: center;
    margin-bottom: 8px;
  }
`;

interface ColorNumber {
  number: number;
}

const ScoreText = styled(AppText)<ColorNumber>`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: ${props =>
    props.number >= 80
      ? signalColors.dark_green
      : props.number >= 20 && props.number < 80
      ? signalColors.dark_yellow
      : signalColors.dark_red};
  background-color: ${props =>
    props.number >= 80
      ? signalColors.light_green
      : props.number >= 20 && props.number < 80
      ? signalColors.light_yellow
      : signalColors.light_red};
  padding: 2px 8px;
  margin: 0px;
  margin-right: 16px;
  max-width: fit-content;
  height: fit-content;
  border-radius: 8px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 21px;
    margin-right: 8px;
  }
`;

const ResearchWrapper = styled(Wrapper)`
  max-width: 1250px;
  padding: 0px 25px;
  @media ${mobileSize} {
    padding: 0px 20px;
  }
`;

const SectionTitleDiv = styled.div`
  position: relative;
  display: flex;
  margin-top: 64px;
  @media ${mobileSize} {
    margin-top: 48px;
  }
`;

const SectionAfter = styled.div`
  display: block;
  width: 100%;
  background-color: ${theme.PITCH_BLACK};
  margin: auto 0px;
  height: 1px;
  width: 100%;
`;

const SectionTitle = styled(AppText)`
  font-size: 28px;
  line-height: 39px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  width: max-content;
  margin-right: 16px;
  white-space: nowrap;
  @media ${mobileSize} {
    font-size: 20px;
    line-height: 30px;
    margin-right: 12px;
  }
`;

const ResearchTitle = styled(AppText)`
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
  font-weight: 700;
  @media ${mobileSize} {
    font-size: 26px;
    line-height: 32px;
  }
`;

const ResearchSubTitle = styled(AppText)`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: ${theme.GREY_700};
  max-width: 995px;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 23px;
  }
`;

const ResearchSubTitleTop = styled(ResearchSubTitle)`
  margin: 0px;
  line-height: 31px;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 25px;
    padding: 0px;
    height: 25px;
  }
`;

const LongDescriptionText = styled.div`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.2px;
  color: ${theme.GREY_700};
  margin: 0px;
  padding: 0px;
  font-weight: 400;
  margin-right: 48px;
  @media ${laptopSize} {
    font-size: 16px;
    line-height: 23px;
    margin-right: 0px;
    margin-top: 24px;
  }
`;

const OurTeamContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  margin-top: 150px;
  background-color: ${theme.WHITE_COLOR};
  /* height: 600px; */
  @media ${laptopSize} {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.WHITE_COLOR};
  /* height: 2000px; */
  /* background: grey; */
`;

export default IndexTemplate;
